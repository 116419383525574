import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
// import { useAppContext } from "../libs/contextLib";
import { ToastContainer, toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import { InfoCircle, EyeFill } from "react-bootstrap-icons";
import { API_BASE_PATH } from "../../constants/workerConstants";
import { connect } from "react-redux";

const AdminAddPatient = (props) => {
  //   const network = (localStorage.getItem("networkid"));
  const [relations, setRelations] = useState([]);
  const history = useHistory();
  const [wait, setWait] = useState(false);
  const [userName, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [network, setNetwork] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [quest, setQuest] = useState("");
  const [answer, setAnswer] = useState("");
  //   const [doctor, setDoctor] = useState("");
  const [doctorList, setdoctorList] = useState([]);

  const [disableSubmit, setDisableSubmit] = useState(false);

  //   useEffect(() => {
  //     async function fetchData() {
  //       setWait(true);
  //       const res = await fetch("https://staging-api.seniorconnex.com/user/relationships");
  //       res.json()
  //         .then(res => {
  //           setRelations(res.result.relations);
  //           setWait(false);
  //         })
  //         .catch(err => console.error(err));
  //     }
  //     if (wait === false && relations.length === 0) {
  //       fetchData();
  //     }
  //     async function fetchData() {
  //         setWait(true);
  //         const res = await fetch("https://staging-api.seniorconnex.com/superAdmin/doctorList");
  //         res.json()
  //           .then(res => {
  //             setdoctorList(res.result.list);
  //             setWait(false);
  //             // console.log("result",res.result.list)
  //           })
  //           .catch(err => console.error(err));
  //       }
  //       if (wait === false && doctorList.length === 0) {
  //         fetchData();
  //       }
  //   },[]);

  function validateForm() {
    return (
      email.length > 0 && password.length > 0 && password === confirmPassword
    );
  }

  function toggleShow(field, value) {
    if (field === "password") setShowPassword(!value);
    else {
      setShowConfirmPassword(!value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setDisableSubmit(true);
    if (isValidPhoneNumber(phone)) {
      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[^a-zA-Z0-9])(?=.{8,})"
      );
      var test = strongRegex.test(password);
      if (validateForm()) {
        fetch(`${API_BASE_PATH}/user/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // {"userRole":"0","relationship":"5e96d8c168cf04250a88ac60","networkName":"abhay family","network":"5ea000d3f715531874a5db46","password":"qwerty"}
          body: JSON.stringify({
            username: userName.trim(),
            emailId: email.trim(),
            password: password,
            userRole: "1",
            deviceType: "WEB",
            firstName: firstName,
            lastName: lastName,
            address: address,
            network:props.userData.network._id,
            securityQuestion: quest,
            answer: answer.trim(),
            phno: phone,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((res) => {
                toast(res.responseMessage, {
                  toastId: "signup_success",
                });
                props.handleClose();
                props.handleSave();
                window.location.reload();
              });
            } else {
              response.json().then((res) => {
                toast.error(res.responseMessage, { toastId: "signup_error" });
                console.log(res);
                setDisableSubmit(false);
              });
            }
          })
          .catch((error) => {
            console.error(error);
            setDisableSubmit(false);
          });
      } else {
        if (!test) {
          toast.error(
            "Password should contain min. 8 characters with 1 uppercase, 1 lowercase, 1 number and No special characters",
            { toastId: "password_error" }
          );
        } else {
          toast.error("Password does not match", {
            toastId: "password_match_error",
          });
        }
        setDisableSubmit(false);
      }
    } else {
      setDisableSubmit(false);
      toast.error("Cell Number is not valid");
      return false;
    }
  }

  return (
    <>
      <div
        style={{
          padding: "10px 30px",
          background: "#ECECEC",
          margin: "-1.1rem -0.5rem -1.1rem -1.1rem",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h5 style={{ fontWeight: 700, color: "#303030" }}>
            Admin Patient Form
          </h5>

          <br />
          <br />
          <Row style={{ marginTop: "-1rem" }}>
            <Col md={6}>
              <FormGroup controlId="firstName" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>First Name</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required={true}
                  style={{
                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup controlId="lastName" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Last Name</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required={true}
                  style={{
                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="firstName" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Username</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={userName}
                  onChange={(e) => setUsername(e.target.value)}
                  required={true}
                  style={{
                    marginBottom: "12px",
                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="email" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Email</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                  style={{
                    marginBottom: "10px",

                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="phone" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  Phone Number<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                {/* <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="ph_help">
                      You will be getting text messages for verification
                      purposes
                    </Tooltip>
                  }
                >
                  <InfoCircle className="pull-right" />
                </OverlayTrigger> */}
                {/* <PhoneInput
                  country="us"
                  autoFormat={false}
                  disabled={false}
                  disableCountryCode={false}
                  value={phone}
                  onChange={setPhone}
                  required={true}
                  inputStyle={{
                    // paddingRight: "22rem",
                    borderColor: "none",
                    border: "none",
                    outline: "none",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // padding: "3rem",
                    width: "100%",
                    background: "#FFFFFF",
                    // marginLeft: "-0.1rem",
                  }}
                  // defaultCountry="US"
                  // country="US"
                /> */}
                <PhoneInput
                  value={phone}
                  onChange={setPhone}
                  required={true}
                  defaultCountry="US"
                />
              </FormGroup>
            </Col>
            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="address" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Address</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required={true}
                  style={{
                    marginBottom: "10px",
                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="quest" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Security Question</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  componentClass="select"
                  value={quest}
                  onChange={(e) => setQuest(e.target.value)}
                  required={true}
                  style={{
                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                >
                  <option key="0"></option>
                  <option key="1" value="What city were you born in?">
                    What city were you born in?
                  </option>
                  <option key="2" value="What is your favorite food?">
                    What is your favorite food?
                  </option>
                  <option key="3" value="What street did you grow up on?">
                    What street did you grow up on?
                  </option>
                  <option key="4" value="What is your mother’s maiden name?">
                    What is your mother’s maiden name?
                  </option>
                  <option key="5" value="Where did you meet your spouse?">
                    Where did you meet your spouse?
                  </option>
                  <option key="6" value="What Is your favorite book?">
                    What Is your favorite book?
                  </option>
                </FormControl>
              </FormGroup>
            </Col>

            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="answer" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Security Answer</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  required={true}
                  style={{
                    background: "#FFFFFF",
                    border: "none",
                    // border: "0px 0px 1px 0px solid #CBCACA",
                    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                    borderRadius: " 11px",
                    // borderStyle: " 1px #CBCACA",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="password" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Password</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                {/* <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="p_help">
                      Password should contain min. 8 characters with 1
                      uppercase, 1 lowercase, 1 number and No special characters
                    </Tooltip>
                  }
                >
                  <InfoCircle className="pull-right" />
                </OverlayTrigger> */}
                <InputGroup>
                  <FormControl
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                    style={{
                      background: "#FFFFFF",
                      border: "none",
                      paddingRight: "2rem",
                      // border: "0px 0px 1px 0px solid #CBCACA",
                      boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                      borderRadius: " 11px",
                      position: "relative",
                      // borderStyle: " 1px #CBCACA",
                    }}
                  />
                  <InputGroup.Addon
                    style={{
                      // padding: "6px",
                      borderLeft: "0",
                      verticalAlign: "middle",
                      // border: "1px solid #ccc"
                      textAlign: "center",
                      // background: "#FFFFFF",
                      position: "absolute",
                      zIndex: "9999",
                      bottom: "0.5rem",
                      right: "0.6rem",
                    }}
                    onClick={() => {
                      toggleShow("password", showPassword);
                    }}
                  >
                    <EyeFill />
                  </InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6} style={{ marginTop: "1rem" }}>
              <FormGroup controlId="confirmPassword" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>Confirm Password</span>
                  <b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <InputGroup>
                  <FormControl
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required={true}
                    style={{
                      background: "#FFFFFF",
                      border: "none",
                      paddingRight: "2rem",
                      // border: "0px 0px 1px 0px solid #CBCACA",
                      boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
                      borderRadius: " 11px",
                      position: "relative",
                      // borderStyle: " 1px #CBCACA",
                    }}
                  />
                  <InputGroup.Addon
                    style={{
                      // padding: "6px",
                      borderLeft: "0",
                      verticalAlign: "middle",
                      // border: "1px solid #ccc"
                      textAlign: "center",
                      // background: "#FFFFFF",
                      position: "absolute",
                      zIndex: "9999",
                      bottom: "0.5rem",
                      right: "0.6rem",
                    }}
                    onClick={() => {
                      toggleShow("confirm_password", showConfirmPassword);
                    }}
                  >
                    <EyeFill />
                  </InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <button
              bsSize="medium"
              className="btn btn-danger btn-md"
              onClick={props.handleClose}
              style={{
                // marginLeft: "40rem",
                padding: "0.3rem 2.5rem 0.3rem 2.5rem",
                borderRadius: "12px",
                marginRight: "1rem",
              }}
            >
              Close
            </button>
            <Button
              bsSize="medium"
              disabled={disableSubmit}
              type="submit"
              style={{
                backgroundColor: "rgb(29, 117, 189)",
                color: "rgb(255, 255,255)",
                padding: "0.3rem 2.5rem 0.3rem 2.5rem",
                marginRight: "-31rem",
                borderRadius: "12px",
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
  };
};

export default connect(mapStateToProps)(AdminAddPatient);
