import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {RootReducer} from "../reducers";


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['appReducer', 'authReducer'],
    whitelist: ['persist']
}

const persisted = persistReducer(persistConfig, RootReducer)


export const store = createStore(persisted)
export const persistor = persistStore(store)

