import React from 'react';
import AppRoutes from "./routes";
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store, persistor} from "./redux/store";
import {PersistGate} from 'redux-persist/integration/react'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
            <ToastContainer />
                <BrowserRouter>
                       <AppRoutes/>
                </BrowserRouter>
                
            </PersistGate>
        </Provider>
    );
}

export default App;