import React from 'react';
import Video, {connect} from 'twilio-video';
import Room from "./Room";
import axios from "axios";
import './Twilio.css';
import {connect as reduxConnect} from 'react-redux';
import {API_BASE_PATH} from "../../../constants/workerConstants";
import {useHistory} from "react-router-dom";
import {AppActionTypes} from "../../../redux/action-types";

const TwilioCall = props => {

    const history = useHistory();
    //states
    const [room, setRoom] = React.useState(null);

    const [callStatus, setCallStatus] = React.useState(0);


    // const [identity, setIdentity] = React.useState('');

    // join room function


    const userData = props.callRow;
   
    // function disconnectRoom(){
    //     console.log("leave room")
    //     setRoom(null);
    //     // returnToLobby();
    //     // setCallStatus(0);
       
    //   };

    React.useEffect(() => {


        if (userData) {
            joinRoom();
            console.log('debug call', userData);
        } else {
            history.goBack();
        }

        return () => {
            returnToLobby();
        }

    }, [])

    function timeout(ms)
    {
       return new Promise(resolve => setTimeout(resolve, ms));
    }
    const joinRoom = async () => {

        // let audio = new Audio("https://audiosoundclips.com/wp-content/uploads/2019/11/Incomingringsound.mp3");
        //
        //  audio.play();
  

        await setCallStatus(2)
        console.log("before")
        // await timeout(10000);
        try { 
            const response = await fetch(`${API_BASE_PATH}/twilio/getTwilioToken?id=${userData._id}&roomName=${props.userData.network.networkName}&shouldInitiateCall=0`, {
                headers: {
                    "content-type": "application/json",
                    "authorization": props.authToken
                }
            });
            const data = await response.json();

            console.log("after")

            const room = await connect(data.result.token, {
                name: props.userData.network.networkName,
                audio: true,
                video: true,
                preferredVideoCodecs: ['VP8']
            });
            const response1= await fetch(`${API_BASE_PATH}/twilio/getTwilioToken?id=${userData._id}&roomName=${props.userData.network.networkName}&shouldInitiateCall=1`, {
                headers: {
                    "content-type": "application/json",
                    "authorization": props.authToken
                }
            });
            // const data = await response.json();
           console.log("room connected",room);
            setRoom(room);
          
        } catch (err) {
            console.log(err);
            returnToLobby();
            
        }
    }

    // Return to lobby
    const returnToLobby = () => {
        setRoom(null);
        setCallStatus(3)
        history.goBack();

    }


    // Ui rendering
    return <div className={callStatus !== 0 ? "twilio__container" : ''}>

        {/*name and status*/}
        {
            callStatus !== 0 && <div className='callScreen__connecting'>
                <p className='name'>{userData.firstName} {userData.lastName}</p>
                <p className='status'>{callStatus === 2 ? 'Connecting' : callStatus === 1 ? 'Connected' : ''}</p>
            </div>
        }


        {/*Bubble*/}
        {
            callStatus !== 0 && <div className='initial__bubble'>
                {userData.firstName.charAt(0).toUpperCase()}{userData.lastName.charAt(0).toUpperCase()}
            </div>
        }


        {
            room === null ? <div className="lobby">

                </div>
                :
                <Room setCallStatus={status => setCallStatus(status)} patientName={'Dummy User'} callStatus={callStatus}
                      returnToLobby={() => returnToLobby()}
                      room={room}/>
        }

    </div>

};


const mapStateToProps = state => {
    return {
        authToken: state.persist.authToken,
        callRow: state.persist.callRow,
        userData: state.persist.userData
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setCallRow: row => dispatch({
            type: AppActionTypes.SET_CALL_ROW,
            payload: row
        }),

        removeCallRow: () => dispatch({
            type: AppActionTypes.REMOVE_CALL_ROW,
        })

    }
}


export default reduxConnect(mapStateToProps, mapDispatchToProps)(TwilioCall);