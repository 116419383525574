import {AppActionTypes, AuthActionTypes} from "../action-types";
import {SET_DASHBOARD_HEART_RATE_ID, SET_SUPER_ADMIN_HEART_ID} from "../action-types/app-action-types";

const initialState = {
    userData: null,
    authToken: null,
    hospitalId: null,
    hospitalName: null,
    AppNetworkId: null,
    AppNetworkHeartId: null,
    superAdminHeartID: null,
    callRow: null,
    dashboardHeartRateID: null,
    appAdminId: null,
    nestedNetworkId: null
};


const persistedReducer = (state = initialState, action) => {

    switch (action.type) {


        case AuthActionTypes.LOGIN :
            console.log('debug login')
            return {
                ...state,
                userData: action.payload,
                authToken: action.token
            }


        case AuthActionTypes.LOGOUT :
            console.log('debug logout')
            return {
                ...state,
                userData: null,
                authToken: null
            }

        case AppActionTypes.SET_HOSPITAL_ID:

            return {
                ...state,
                hospitalId: action.id,
                hospitalName: action.hosp
            }

        case AppActionTypes.SET_APP_NETWORK_ID :

            return {
                ...state,
                AppNetworkId: action.payload
            }

        case AppActionTypes.SET_APP_NETWORK_HEART_ID :


            return {
                ...state,
                AppNetworkHeartId: action.payload
            }

        case AppActionTypes.SET_SUPER_ADMIN_HEART_ID :

            return {
                ...state,
                superAdminHeartID: action.payload
            }

        case AppActionTypes.SET_CALL_ROW:

            return {
                ...state,
                callRow: action.payload
            }

        case AppActionTypes.REMOVE_CALL_ROW:

            return {
                ...state,
                callRow: null
            }

        case AppActionTypes.SET_DASHBOARD_HEART_RATE_ID:
            return {
                ...state,
                dashboardHeartRateID: action.id
            }

        case AppActionTypes.SET_NETWORK_ADMIN_ID:

            return {
                ...state,
                appAdminId: action.id
            }


        case AppActionTypes.SET_SUPER_ADMIN_NETWORK_NESTED_ID:


            console.log('akshay__aa', action.payload)

            return {
                ...state,
                nestedNetworkId: action.payload
            }


        default:
            return state


    }


}

export default persistedReducer;