import React, { useEffect, useState } from "react";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import { useHistory, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CopyRight from "../../../components/elements/copyright/CopyRight";
import { connect } from "react-redux";
import { AppActionTypes, AuthActionTypes } from "../../../redux/action-types";
import { API_BASE_PATH } from "../../../constants/workerConstants";
// import DoctorCard from "../../doctor-card";
import AdminPatientList from "../../admin-patient-list";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));
const Dashboard = (props) => {
  console.log(props.userData.userRole);
  console.log(props.userData);
  const history = useHistory();
  // const [rows, setRows] = useState([]);
  const [rows, setRows] = useState({
    users: [],
    page: 10,
  });

  // // redirect if user data is present.
  React.useEffect(() => {
    // console.log("debug dashboard", props.userData.userRole);

    if (props.userData) {
      history.push("/dashboard");
      // fetchData();
      // async function fetchData() {
      //   const res = await fetch(`${API_BASE_PATH}/user/hospitalList`);
      //   res
      //     .json()
      //     .then((res) => {
      //       // setRows(res.result.list);
      //       setRows({ users: res.result.list, page: rows.page });
      //       console.log(res.result.list);
      //       // setWait(false);
      //     })
      //     .catch((err) => console.error(err));
      // }
    } else {
      history.push("/");
    }
  }, [props.userData]);

  useEffect(() => {
    if (props.userData.userRole === 3) {
      fetchData();
      async function fetchData() {
        const res = await fetch(`${API_BASE_PATH}/user/hospitalList`);
        res
          .json()
          .then((res) => {
            // setRows(res.result.list);
            setRows({ users: res.result.list, page: rows.page });
            // console.log(res.result.list);
            // setWait(false);
          })
          .catch((err) => console.error(err));
      }
    }
  }, []);
  const viewMore = () => {
    setRows((prevState) => {
      return {
        page: prevState.page + 5,
        users: prevState.users,
      };
    });
    //   console.log(rows);
  };

  function viewLess() {
    setRows((prevState) => {
      return {
        page: 5,
        users: prevState.users,
      };
    });
  }
  // console.log(props);
  return (
    <div>
      <NavbarSuperAdmin />

      {props.userData && props.userData.userRole === 3 ? (
        <>
          <React.Fragment>
            <h4
              style={{
                marginTop: "100px",
                marginLeft: "183px",
                fontWeight: "700",
              }}
            >
              Organization List
            </h4>
            {/* <Title><b  style={{fontSize:"15px"}}>Organization's List</b></Title> */}
            <Table
              style={{ marginTop: "28px", marginLeft: "183px", width: "77%" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Organization Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Location</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>City</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>State</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Country</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              {rows.users.length == 0 ? (
                <div
                  className="text-center mt-5 "
                  style={{
                    backgroundColor: "rgb(29, 117, 189)",
                    color: "rgb(255, 255,255)",
                    padding: "0.5rem",
                    marginRight: "-45rem",
                  }}
                >
                  No Data Available
                </div>
              ) : (
                <TableBody>
                  {rows.users.slice(0, rows.page).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          props.dispatchHospitalId(row._id, row.hospitalName);
                          // console.log(row);
                          history.push(`/superadminhospitaldetail/`);
                        }}
                      >
                        <b style={{ color: "black" }}>{row.hospitalName}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.location}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.city}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.state}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.country}</b>
                      </TableCell>
                    </TableRow>
                  ))}
                  {
                    <div
                      className="text-center mt-5"
                      style={{ marginRight: "-45rem" }}
                    >
                      {rows.page >= rows.users.length &&
                      rows.users.length > 5 ? (
                        <div>
                          <button
                            className="btn btn-warning "
                            onClick={viewLess}
                          >
                            View Less
                          </button>
                        </div>
                      ) : (
                        <div>
                          {rows.users.length > 5 ? (
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "rgb(29, 117, 189)",
                                color: "rgb(255, 255,255)",
                              }}
                              onClick={viewMore}
                            >
                              View More
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  }
                </TableBody>
              )}
            </Table>
            {/* <div className={classes.seeMore}>
        <Link color="primary" to="/patientdetail" >
          view More
        </Link>
      </div> */}
          </React.Fragment>
          {/* <div className="text-center m-2">
            {rows.page >= rows.users.length && rows.users.length > 5 ? (
              <button className="btn btn-warning " onClick={viewLess}>
                View Less
              </button>
            ) : (
              <button
                className="btn  "
                style={{
                  backgroundColor: "rgb(29, 117, 189)",
                  color: "rgb(255, 255,255)",
                }}
                onClick={viewMore}
              >
                View More
              </button>
            )}
          </div> */}
        </>
      ) : (
        <div style={{ marginTop: 100 }}>
          {/* <DoctorCard /> */}

          <AdminPatientList />
        </div>
      )}
      {/* <div className="text-center m-3">
        <button className="btn btn-primary " onClick={viewMore}>
          View More
        </button>
      </div> */}
      <Box pt={4}>
        <CopyRight />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch({
        type: AuthActionTypes.LOGOUT,
      }),

    dispatchHospitalId: (id, hosp) =>
      dispatch({
        type: AppActionTypes.SET_HOSPITAL_ID,
        id,
        hosp,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
