import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";
function PatientInfoDialog(props) {
  const { patientsInfo, setPatientInfo } = props;
  const history = useHistory();
  return (
    <div>
      <Dialog open={patientsInfo.isOpen} style={{}}>
        <DialogTitle>
          {" "}
          <div style={{ display: "flex" }}>
            <Typography
              variant="h4"
              style={{ marginLeft: "100px", flexGrow: 1 }}
            >
              CALENDAR
            </Typography>
            <Button
              color="secondary"
              size="large"
              onClick={() => setPatientInfo({ isOpen: false })}
            >
              X
            </Button>{" "}
          </div>
        </DialogTitle>

        <DialogContent
          dividers
          variant="h4"
          style={{ width: "430px", height: "320px" }}
        >
          <Paper
            variant="h4"
            elevation={3}
            style={{
              margin: "10px",
              height: "35px",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/notes");
              }}
            >
              Notes
            </Typography>
          </Paper>
          <Paper
            variant="h4"
            elevation={3}
            style={{
              margin: "10px",
              height: "35px",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/dailytask");
              }}
            >
              Daily Tasks
            </Typography>
          </Paper>

          <Paper
            elevation={3}
            style={{
              margin: "10px",
              height: "35px",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/medicinemanagement");
              }}
            >
              {" "}
              Medicine Management
            </Typography>
          </Paper>

          <Paper
            variant="h3"
            elevation={3}
            style={{
              margin: "10px",
              height: "35px",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/appointments");
              }}
            >
              Appointments
            </Typography>
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default PatientInfoDialog;
