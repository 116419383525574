import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
} from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function AddDialog(props) {
  const { addDialog, setAddDialog } = props;
  const [userName, setUserName] = useState();
  const [firstId, setFirstId] = useState();
  const [secondId, setSecondId] = useState();
  const history = useHistory();
  const [value, setValue] = useState("");

  useEffect(() => {
    getName();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
    console.log("setValue", value);
  };
  const getName = async () => {
    axios
      .get(
        `${API_BASE_PATH}/user/getMemberList`,

        {
          headers: {
            authorization: props.authToken,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setUserName(resp.data.result.list[0].firstName);
          setFirstId(resp.data.result.list[0]._id);
          console.log("resp", resp.data.result.list[0]._id);
          setSecondId(resp.data.result.list[1]._id);
        }
      });
  };

  const passData = async () => {
    if (value.trim() == "") {
      toast.error(" Please Enter Notes");
      return;
    } else
      axios
        .post(
          `${API_BASE_PATH}/todo/${addDialog.apiText}`,
          {
            date: "" + new Date(new Date(addDialog.date).toDateString()),
            content: value,
            members: [firstId, secondId],
          },
          {
            headers: {
              authorization: props.authToken,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            var a = new Date(new Date(addDialog.date).toDateString());
            addDialog.xyzFun(a);

            setAddDialog({ isOpen: false });
            setValue("");
            // window.location.reload();
          }
          console.log("uses", resp);
        });
  };

  return (
    <div>
      <Dialog
        open={addDialog.isOpen}
        PaperProps={{
          style: {
            minWidth: "250px",
            overflow: "hidden",
            width: "26%",
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: "rgb(29, 117, 189)" }}>
          {" "}
          <div
            style={{
              backgroundColor: "rgb(29, 117, 189)",
              display: "flex",
              color: "white",
            }}
          >
            <Typography
              variant="h6"
              style={{ flexGrow: 1, textAlign: "center", width: "20%" }}
            >
              {/* Add Notes */}
              {addDialog.text}
            </Typography>
          </div>
        </DialogTitle>
        <Container width="50%" height="50%">
          <form style={{ height: "50%" }}>
            <TextField
              required
              value={value}
              multiline={true}
              rows={4}
              fullWidth
              label={addDialog.text}
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  height: "40%",
                },
              }}
              onChange={handleChange}
            />
          </form>
        </Container>

        <DialogContent
          style={{
            fontWeight: "bold",
            alignItems: "left",
            color: "rgb(29, 117, 189)",
          }}
        >
          <Typography>{addDialog.date}</Typography>
        </DialogContent>
        <DialogContent
          style={{ color: "rgb(29, 117, 189)", textAlign: "center" }}
        >
          {userName}
        </DialogContent>
        <DialogContent style={{ marginLeft: "5px", marginLeft: "auto" }}>
          <DialogTitle>
            {" "}
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                onClick={passData}
                variant="contained"
                style={{
                  display: "flex",
                  color: "white",
                  backgroundColor: "rgb(29, 117, 189)",
                }}
              >
                Done
              </Button>
              <Button
                onClick={() => setAddDialog({ isOpen: false })}
                variant="contained"
                style={{
                  marginLeft: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  color: "rgb(29, 117, 189)",
                }}
              >
                Close
              </Button>
            </div>
          </DialogTitle>
        </DialogContent>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
  };
};
export default connect(mapStateToProps)(AddDialog);
