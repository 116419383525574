import React, { useState } from "react";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TableBody from "@material-ui/core/TableBody";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import { connect } from "react-redux";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { Paper } from "@material-ui/core";
import ContentDialog from "../../../components/ui/app-modal/ContentDialog";
import AddDialog from "../../../components/ui/app-modal/AddDialog";
import dateFormat from "dateformat";
import Spinner from "../Spinner";

function Notes(props) {
  const [dataDays, setDataDays] = useState([]);
  const [changeDate, setChangeDate] = useState(new Date().toDateString());
  const [openTask, setOpenTask] = useState(false);
  const [findTask, setFindTask] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    date: "",
  });
  const [addDialog, setAddDialog] = useState({
    isOpen: false,
    date: "",
    xyzFun: "",
    text: "",
    apiText: "",
  });
  const [clickDate, setClickDate] = useState(new Date().toDateString());
  React.useEffect(() => {
    getDateData();
  }, [changeDate]);
  const xyz = (a) => {
    console.log("a", a);
    setChangeDate(a);
  };
  const getDateData = async () => {
    setLoading(true);
    axios
      .post(
        `${API_BASE_PATH}/todo/CalendardataForMonth`,
        {
          reqDate: "" + new Date(changeDate),
          event: "note",
        },
        {
          headers: {
            authorization: props.authToken,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setDataDays(resp.data.result.days);
          setLoading(false);
          if (resp.data.result.data !== null) {
            setFindTask(resp.data.result.data);
          }
        }
      });
  };

  const values = dataDays.map((item) => {
    var d = new Date(item.date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var date = [year, month, day].join("-");
    var title = item.title;
    return { title, date };
  });

  const particularDate = async (date) => {
    setLoading(true);
    axios
      .post(
        `${API_BASE_PATH}/todo/CalendardataForMonth`,
        {
          reqDate: "" + date,
          event: "note",
        },
        {
          headers: {
            authorization: props.authToken,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          console.log(resp.data.result.data);

          setFindTask(resp.data.result.data);
          setLoading(false);
        }
      });
  };

  const handleDateClick = (dateClickInfo) => {
    setClickDate(dateClickInfo.date);
    setOpenTask(true);
    particularDate(dateClickInfo.date);
    console.log("dateClickInfo", new Date(dateClickInfo.date).toLocaleDateString());
    console.log("dateClickInfo", new Date().toLocaleDateString());
    console.log("compare", new Date(dateClickInfo.date).toLocaleDateString()>=new Date().toLocaleDateString());


  };

  const getDates = (dateInfo) => {
    var chengenewDate = new Date().toUTCString();
    var checkDate = new Date(
      dateInfo.view.getCurrentData().currentDate
    ).toUTCString();

    if (chengenewDate.substring(0, 16) !== checkDate.substring(0, 16)) {
      console.log(
        new Date(chengenewDate).getDate(),
        new Date(checkDate).getDate(),
        checkDate
      );
      setChangeDate(new Date(dateInfo.view.getCurrentData().currentDate));
    }
  };

  return (
    <div>
      <NavbarSuperAdmin />

      <div style={{ position: "relative" }}>
        <Table
          style={{
            marginTop: "15px",
            marginBottom: "100px",
            marginLeft: "82px",
            width: "77%",
          }}
        >
          <TableHead>
            <div class="header" style={{ marginTop: "0px" }}>
              <KeyboardBackspaceIcon
                onClick={() => window.history.back()}
                style={{
                  marginTop: "70px",
                  marginLeft: "-7px",
                  height: "40px",
                  width: "40px",
                }}
              />
              <h4
                style={{
                  position: "relative",
                  marginTop: "-34px",
                  marginLeft: "40px",
                  width: "100%",
                }}
              >
                <b>Notes</b>
              </h4>
              <div
                style={{
                  height: "60px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <FullCalendar
                  height="auto"
                  width="60%"
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={values}
                  dateClick={handleDateClick}
                  initialView="dayGridMonth"
                  eventBorderColor="#ffffcd"
                  eventBackgroundColor="#ffffcd"
                  datesSet={getDates}
                  eventTextColor="black"
                  selectable="true"
                />
              </div>
              {loading && <Spinner />}
              <div
                style={{
                  display: "flex",
                  marginLeft: "80%",
                  marginTop: "-62px",
                }}
                open={openTask}
              >
                {" "}
                <div
                  className="top"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "83%",
                  }}
                >
                  <button
                    disabled={
                      new Date(clickDate).toLocaleDateString()<new Date().toLocaleDateString()
                    }
                    type="button"
                    class="btn btn-primary"
                    style={{
                      marginLeft: "101%",
                      alignItems: "right",
                      width: "414%",
                      maxHeight: "10%",
                      paddingLeft: "2px",
                      backgroundColor: "rgb(29, 117, 189)",
                      color: "rgb(255, 255, 255)",
                    }}
                    onClick={() => {
                      setAddDialog({
                        isOpen: true,
                        date: dateFormat(clickDate, "mm/dd/yyyy"),
                        xyzFun: xyz,
                        text: "Add Notes",
                        apiText: "addNote",
                      });
                    }}
                  >
                    + Add Notes
                  </button>
                </div>
                <div className="down" style={{ marginTop: "57px" }}>
                  <Paper
                    variant="h4"
                    elevation={3}
                    style={{
                      minHeight: "460px",
                      textAlign: "center",
                      width: "240px",
                    }}
                  >
                    {findTask !== undefined && findTask.length === 0
                      ? "There is no data in Notes"
                      : " "}

                    {findTask !== undefined &&
                      findTask.length > 0 &&
                      findTask.map((value) => {
                        return (
                          <div>
                            <div
                              style={{
                                color: "rgb(29, 117, 189)",
                                paddingLeft: "3px",
                                paddingTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  textAlign: "left",
                                  paddingLeft: "3px",
                                }}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: value.content,
                                    date: dateFormat(
                                      value.date,
                                      "mm/dd/yyyy"
                                    ),
                                  });
                                }}
                              >
                                {value.content.length < 30 ? value.content : ""}
                                {value.content.length > 65
                                  ? value.content.slice(0, 65) + "..."
                                  : ""}
                              </div>
                              <div
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "3px",
                                }}
                              >
                                {dateFormat(value.date, "mm/dd/yyyy")}
                              </div>
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                  </Paper>
                </div>
              </div>
            </div>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </div>
      <ContentDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <AddDialog addDialog={addDialog} setAddDialog={setAddDialog} />
    </div>
  );
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
  };
};
export default connect(mapStateToProps)(Notes);
