export const SHOW_APP_MODAL = "SHOW_APP_MODAL";
export const HIDE_APP_MODAL = "HIDE_APP_MODAL";
export const SHOW_APP_INDICATOR = "SHOW_APP_INDICATOR";
export const HIDE_APP_INDICATOR = "HIDE_APP_INDICATOR";



export const SET_HOSPITAL_ID = "SET_HOSPITAL_ID";
export const SET_APP_NETWORK_ID = "SET_APP_NETWORK_ID";
export const SET_APP_NETWORK_HEART_ID = "SET_APP_NETWORK_HEART_ID";
export const SET_SUPER_ADMIN_HEART_ID = "SET_SUPER_ADMIN_HEART_ID";
export const SET_CALL_ROW = "SET_CALL_ROW";
export const REMOVE_CALL_ROW = "REMOVE_CALL_ROW";
export const SET_DASHBOARD_HEART_RATE_ID = "SET_DASHBOARD_HEART_RATE_ID";
export const SET_NETWORK_ADMIN_ID = "SET_NETWORK_ADMIN_ID";
export const SET_SUPER_ADMIN_NETWORK_NESTED_ID = "SET_SUPER_ADMIN_NETWORK_NESTED_ID";