import React from "react";
import Participant from "./Participant";
import callButtonLogo from './assets/end-call.png';

const Room = (props) => {
  //states
  const [remoteParticipants, setRemoteParticipants] = React.useState(
    Array.from(props.room.participants.values())
  );
 
  //   componentMount
  React.useEffect(() => {
    props.setCallStatus(1);
    props.room.on("participantConnected", (participant) =>
      addParticipant(participant)
    );
    props.room.on("participantDisconnected", (participant) =>
      removeParticipant(participant)
    );

    window.addEventListener("beforeunload", leaveRoom);

    return () => {
      leaveRoom();
    };
  }, [props.room]);

  const addParticipant = (participant) => {
    console.log(`${participant.identity} has joined the room.`);
    setRemoteParticipants([...remoteParticipants, participant]);
  };

  const removeParticipant = (participant) => {
    leaveRoom();

    console.log(`${participant.identity} has left the room`);
    setRemoteParticipants(
      remoteParticipants.filter((p) => p.identity !== participant.identity)
    );
  };

  const leaveRoom = () => {
    console.log("leave room")
    props.room.disconnect();
    props.returnToLobby();
    props.setCallStatus(0);
   
  };

  function disconnectRoom(){
    console.log("leave room")
    props.room.disconnect();
    props.returnToLobby();
    props.setCallStatus(0);
   
  };
  const renderCallScreen = () => {
    // trying to connect/Not connected
    if (props.callStatus === 1) {
      return (
        <div className="participants">
          <Participant
            key={props.room.localParticipant.identity}
            localParticipant="true"
            participant={props.room.localParticipant}
          />
          {remoteParticipants.map((participant) => (
            <Participant key={participant.identity} participant={participant} />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="room">
      {remoteParticipants !== null ? renderCallScreen() : <></>}

      <div id="leaveRoom" className={"chat__controls"}>
        <img
          onClick={leaveRoom}
          src={callButtonLogo}
        />
      </div>
    </div>
  );
};

export default Room;
