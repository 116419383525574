// export const API_BASE_PATH = "https://staging-api.seniorconnex.com"; //staging
export const API_BASE_PATH = "https://api.seniorconnex.com"; //production
export const SOCKET_BASE_PATH = "";

export const API_ROUTES = {
  AUTH: {
    LOGIN: "auth/login",
    LOGOUT: "auth/logout",
  },
};
