import React from "react";
import { Typography } from "@material-ui/core";

const CopyRight = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © "}
    Senior Connex, 2018
  </Typography>
);

export default CopyRight;
