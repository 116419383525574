import {combineReducers} from 'redux';
import authReducer from './auth.reducer';
import appReducer from './app';
import persistedReducer from "./persistedReducer";

export const RootReducer = combineReducers({
    appReducer,
    authReducer,
    persist: persistedReducer
});
