import React, {useRef} from 'react';


const Track = (props) => {

    const ref = useRef();
  
    React.useEffect(() => {

        if (props.track) {
            console.log('track',props.track)
            const child = props.track.attach();
            // ref.current.classList.add(props.track.kind);
            // ref.current.appendChild(child)

            document.querySelector('#check__akshay').classList.add(props.track.kind);
            document.querySelector('#check__akshay').appendChild(child)

        }

    }, [props.track])


    return (
        <div id={'check__akshay'} className="track" ref={ref}>
        </div>
    )


}


export default Track;